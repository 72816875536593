import React, { useCallback } from 'react';
// Components
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// Types
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormValues, validateForm } from './form';
import { GraphqlServerEnum } from '~/enums/graphql-server.enum';
import {
  Endpoint,
  useUpdateEndpointMutation,
  GetEndpointInfoDocument,
} from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  endpoint: Endpoint;
  open: boolean;
  onClose: () => void;
}
const UpdateEndpointDialog: React.FC<Props> = (props) => {
  const { open } = props;
  return (
    <Dialog open={open} data-testid="update-endpoint-dialog">
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<Props> = (props) => {
  const { onClose, endpoint } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateEndpoint, { loading: updateEndpointLoading }] = useUpdateEndpointMutation({
    refetchQueries: [
      {
        query: GetEndpointInfoDocument,
        variables: {
          input: {
            endpointId: endpoint.endpointId,
          },
        },
        context: { apiName: GraphqlServerEnum.EndpointService },
      },
    ],
  });
  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await updateEndpoint({
          variables: {
            input: {
              endpointId: endpoint.endpointId,
              updateName: values?.updateName || '',
            },
          },
        });
        onClose();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [updateEndpoint, endpoint, onClose, enqueueSnackbar],
  );
  const formik = useFormik<FormValues>({
    initialValues: {
      updateName: endpoint.name,
    },
    validate: validateForm(t),
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <>
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Typography variant="h5" color="primary" component="p">
            {t('update_endpoint_dialog.title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {t('update_endpoint_dialog.description')}
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="updateName"
                inputProps={{ 'data-testid': 'input-updateName' }}
                label={t('name')}
                helperText={formik.touched && formik.errors.updateName}
                error={Boolean(formik.touched && formik.errors.updateName)}
                onChange={formik.handleChange}
                color="primary"
                value={formik.values.updateName}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
            endIcon={updateEndpointLoading && <CircularProgress size={20} color="inherit" />}
            data-testid="update-button"
          >
            {t('update')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default UpdateEndpointDialog;
