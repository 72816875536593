import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const Loading: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  return (
    <div className={classes.root} data-testid="loading">
      <CircularProgress />
    </div>
  );
};

export default Loading;
