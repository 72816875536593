import React, { useCallback } from 'react';
// Components
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Types
import { GraphqlServerEnum } from '~/enums/graphql-server.enum';
import { AppRoutesEnum } from '~/enums/route.enum';
import {
  GetEndpointInfoDocument,
  ListEndpointsDocument,
  useDeleteEndpointMutation,
} from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  endpointId: string;
  open: boolean;
  onClose: () => void;
}

const DeleteEndpointDialog: React.FC<Props> = (props) => {
  const { open, onClose, endpointId } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteEndpoint] = useDeleteEndpointMutation({
    variables: { input: { endpointId } },
    refetchQueries: [
      {
        query: GetEndpointInfoDocument,
        variables: {
          input: {
            endpointId,
          },
        },
        context: { apiName: GraphqlServerEnum.EndpointService },
      },
      { query: ListEndpointsDocument, context: { apiName: GraphqlServerEnum.EndpointService } },
    ],
  });
  const onSubmit = useCallback(async () => {
    try {
      await deleteEndpoint();
      onClose();
      navigate(AppRoutesEnum.ENDPOINT_SERVICE);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [deleteEndpoint, onClose, enqueueSnackbar]);
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <Dialog open={open} data-testid="delete-endpoint-dialog">
      <form onSubmit={formik.handleSubmit}>
        <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
          <img src="/images/close_icon.svg" alt="" />
        </IconButton>
        <DialogTitle>
          <Typography variant="h5" color="primary" component="p">
            {t('delete_endpoint_dialog.title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {t('delete_endpoint_dialog.description')}
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="outlined" onClick={onClose} data-testid="no-button">
            {t('no')}
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={!formik.isValid || formik.isSubmitting}
            endIcon={
              formik.isSubmitting && (
                <CircularProgress size={20} color="inherit" data-testid="circular-progress" />
              )
            }
            data-testid="yes-button"
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteEndpointDialog;
