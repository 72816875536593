import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
}

const HowToRegisterNewAccountDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();

  return (
    <Dialog open={open} data-testid="unenroll-confirmation-dialog">
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>

      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('dialog_guide_how_to_register_a_new_account.title')}
        </Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <Trans t={t} i18nKey="dialog_guide_how_to_register_a_new_account.content">
          <Typography variant="body2" className={classes.title}>
            Google account
          </Typography>
          <Typography variant="body2" className={classes.content1}>
            If you are a first time user of G.U.Account Portal and already have a Google account,
            please select [Sign in with Google] and your account will be automatically registered.
          </Typography>
          <Typography variant="body2" className={classes.title}>
            E-mail address other than Google account
          </Typography>
          <Typography variant="body2" className={classes.content2}>
            In case you will use an e-mail address other than your Google account, please click the
            [Sign in with email] button and enter the e-mail address you want to register. Name and
            password input box will display for the first time registration. Please perform the
            initial registration from that.
          </Typography>
        </Trans>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="primary" variant="contained" onClick={onClose}>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HowToRegisterNewAccountDialog;
