import React, { useEffect, useMemo } from 'react';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import * as locales from '@mui/material/locale';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './AppRouter';
import i18nMain from './i18n';
import theme from './styles/theme';
import { firebaseConfig } from '~/configs/firebase.config';
import { initFirebaseAuth } from '~/hooks/with-firebase-auth';

interface Props {}

initFirebaseAuth(firebaseConfig);

const App: React.FC<Props> = (props) => {
  const { i18n } = useTranslation('translation', { i18n: i18nMain });

  useEffect(() => {
    const currentLanguage = window.localStorage.getItem('language');
    if (currentLanguage !== i18n.language) {
      window.localStorage.setItem('language', i18n.language);
    }
  }, [i18n.language]);

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[i18n.language === 'ja' ? 'jaJP' : 'enUS']),
    [i18n.language],
  );

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeWithLocale}>
          <I18nextProvider i18n={i18nMain} defaultNS="translation">
            <CssBaseline />
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              TransitionComponent={Collapse}
            >
              <AppRouter />
            </SnackbarProvider>
          </I18nextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

export default App;
