import React, { useEffect } from 'react';
// Components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// Types
// Styles
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import EndpointsListSection from './endpoints-list-section';
import withAcceptTermsGuard from './withAcceptTermGuard';
import UserLayout from '~/components/app-layout';
import LinkButton from '~/components/link-button';
import { AppRoutesEnum } from '~/enums/route.enum';
import { withApolloClient } from '~/graphql/client';
import {
  useGetCurrentPlanQuery,
  useListEndpointsLazyQuery,
} from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { getEndpointPlanNameI18n, getPeriodI18n } from '~/utils/render.util';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const EndpointService = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { data: currentPlanData, loading: loadingCurrentPlanData } = useGetCurrentPlanQuery();
  const { t } = useTranslation();
  const [
    _,
    { data: listEndpointsData, loading: loadingListEndpointsData, refetch: refetchListEndpoints },
  ] = useListEndpointsLazyQuery({ fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    document.title = t('endpoint_service_page.endpoint_service_title');
  }, [t]);

  useEffect(() => {
    refetchListEndpoints();
  }, [refetchListEndpoints]);

  return (
    <UserLayout data-testid="development-program-section">
      <Box>
        <Typography variant="h5">{t('endpoint_service_page.service_list_title')}</Typography>
        <Typography color="textSecondary" className={classes.description}>
          {t('endpoint_service_page.service_list_description')}
        </Typography>
      </Box>
      <Grid className={classes.grid} container spacing={3}>
        <Grid item xs={12} lg={4}>
          <div className={classes.flexBlock}>
            <div>
              <div>
                <Typography variant="body1">{t('endpoint_service_page.requests_today')}</Typography>
                <IconButton
                  className={classes.buttonHelp}
                  component="a"
                  color="inherit"
                  target="_blank"
                  href="https://doc.account.gu.net/help/endpoint-service/requests-today"
                >
                  <HelpIcon />
                </IconButton>
              </div>
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                {listEndpointsData?.endpointServiceListEndpoints.requestCount.toLocaleString() ??
                  '-'}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <Typography variant="body1" className={classes.flexBlockTitle}>
                {t('endpoint_service_page.request_limit')}
              </Typography>
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                {currentPlanData?.getCurrentPlan.requestLimitCount.toLocaleString() ?? '-'}/
                {currentPlanData
                  ? getPeriodI18n(t, currentPlanData.getCurrentPlan.requestLimitPeriod)
                  : '-'}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className={classes.block}>
            <div>
              <Typography variant="body1" className={classes.blockTitle}>
                {t('endpoint_service_page.total_endpoint')}
              </Typography>
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                {listEndpointsData?.endpointServiceListEndpoints.endpoints.length.toLocaleString() ??
                  '-'}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <Typography variant="body1" className={classes.blockTitle}>
                {t('endpoint_service_page.endpoint_limit')}
              </Typography>
              <Typography variant="body1" fontWeight="bold" textAlign="center">
                {currentPlanData?.getCurrentPlan.endpointLimitCount.toLocaleString() ?? '-'}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className={classes.block}>
            <div>
              <Typography variant="body1" className={classes.blockTitle}>
                {t('current_plan')}
              </Typography>
              {loadingCurrentPlanData ? (
                <Skeleton width={50} />
              ) : (
                <Typography variant="body1" fontWeight="bold">
                  {getEndpointPlanNameI18n(t, currentPlanData?.getCurrentPlan.plan)}
                </Typography>
              )}
            </div>
            <div>
              <Button variant="outlined" size="small" disabled>
                {t('upgrade')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <EndpointsListSection
        endpoints={listEndpointsData?.endpointServiceListEndpoints.endpoints}
        loading={loadingListEndpointsData}
      />
      <LinkButton
        className={classes.backButton}
        route={AppRoutesEnum.HOME}
        text={t('back_home')}
        color="primary"
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        size="large"
        dataTestId="exit-button"
      />
    </UserLayout>
  );
};

export default compose(withApolloClient, withAcceptTermsGuard)(EndpointService);
