import React, { useMemo } from 'react';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Drawer from '@mui/material/Drawer';

import { useTranslation } from 'react-i18next';
import NavigatorMenu from '~/components/navigator-menu';
import { AppRoutesEnum } from '~/enums/route.enum';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}

export type NavigatorItemConfig =
  | {
      path?: string;
      label: string;
      icon?: JSX.Element;
      children?: NavigatorItemConfig[];
    }
  | 'divider';

const SideBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, openDrawer, onCloseDrawer } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();

  const config = useMemo<NavigatorItemConfig[]>(
    () => [
      {
        path: AppRoutesEnum.HOME,
        label: t('home'),
        icon: <HomeIcon />,
      },
      {
        path: AppRoutesEnum.DEVELOPMENT_PROGRAM,
        label: t('development_program.development_program_title'),
        icon: <DeveloperBoardOutlinedIcon />,
      },
      {
        path: AppRoutesEnum.ENDPOINT_SERVICE,
        label: t('endpoint_service_page.endpoint_service_title'),
        icon: <ManageAccountsIcon />,
      },
    ],
    [t],
  );

  return (
    <Drawer
      variant={isTemporarySideBar ? 'temporary' : 'persistent'}
      open={isTemporarySideBar ? openDrawer : true}
      onClose={onCloseDrawer}
      classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      data-testid="side-bar"
    >
      <NavigatorMenu
        config={config}
        isTemporarySideBar={isTemporarySideBar}
        onCloseDrawer={onCloseDrawer}
      />
    </Drawer>
  );
};

export default SideBar;
