import { IsNotEmpty } from 'class-validator';
import { validateObject } from '~/common/validator/class-validator-helper';
import { EndpointType, NetworkType } from '~/graphql/endpoint-service/types';

export class FormValues {
  @IsNotEmpty({
    context: {
      i18n: {
        key: 'form_validation.isNotEmpty',
      },
    },
  })
  endpointType?: EndpointType;

  @IsNotEmpty({
    context: {
      i18n: {
        key: 'form_validation.isNotEmpty',
      },
    },
  })
  networkType?: NetworkType;
}

export const initialValues: FormValues = {
  endpointType: EndpointType.HttpParameter,
  networkType: NetworkType.Joc,
};

export const validateForm = (t: any) => async (values: any) =>
  await validateObject(t, Object.assign(new FormValues(), values));
