import { makeStyles } from 'tss-react/mui';
import { HEADER_HEIGHT } from '~/constants/layout';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: '1201!important',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    height: HEADER_HEIGHT,
  },
  menuIcon: {
    cursor: 'pointer',
    visibility: 'hidden',
  },
  menuIconTemporarySideBar: {
    visibility: 'visible',
  },
  toolbarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbarContentRight: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gap: 0,
    },
  },
  displayNameText: {
    marginRight: 10,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  emailText: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  avatar: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  avatarImg: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginRight: 10,
  },
  language: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginLeft: 30,
      marginRight: 30,
    },
  },
  languageText: {
    marginRight: 10,
  },
  buttonHelp: {
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logo: {
    color: theme.colors.midnight,
    width: 220,
    display: 'block',
    pointerEvents: 'none',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
}));

export { useStyles };
