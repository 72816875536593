import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    borderRadius: 4,
    height: 48,
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 40,
    '& img': {
      width: 23,
      height: 23,
    },
  },
  divider: {
    margin: 1,
  },
  itemText: {
    span: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
  },
  paper: {
    width: 290,
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  navigatorItem: {
    flex: 1,
    height: '100%',
  },
}));

export { useStyles };
