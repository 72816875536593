import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  resourceItem: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  root: {
    width: '100%',
    marginTop: 24,
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
    [theme.breakpoints.up('md')]: {
      marginTop: 32,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3),
    },
  },
  table: {
    borderTop: `1px solid ${theme.colors.gainsboro}`,
  },
  row: {
    height: 60,
  },
  cell: {
    borderBottom: `1px solid ${theme.colors.gainsboro}`,
    minWidth: 200,
    padding: theme.spacing(0, 2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    marginRight: 10,
    wordBreak: 'break-all',
  },
  icon: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  backButton: {
    marginTop: theme.spacing(2),
  },
}));

export { useStyles };
