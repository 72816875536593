import { makeStyles } from 'tss-react/mui';

import { closeDialogButton } from '~/styles/common';

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    ...closeDialogButton(theme),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  content1: {
    marginBottom: theme.spacing(3),
  },
  content2: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(0, 3, 2, 3),
  },
  dialogActions: {
    rowGap: theme.spacing(2),
    flexWrap: 'wrap-reverse',
    justifyContent: 'end',
  },
}));

export { useStyles };
