import React, { useState } from 'react';
// Components
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
// Types
import { Endpoint, EndpointType } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  apiKey: string;
  endpoint: Endpoint;
  open: boolean;
  onClose: () => void;
}

const EndpointGuideDialog: React.FC<Props> = (props) => {
  const { open, onClose, apiKey, endpoint } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const [openTip, setOpenTip] = useState(false);
  const [showCopy, setShowCopy] = useState(false);

  const handleCloseTip = (): void => {
    setOpenTip(false);
  };

  const handleClickButton = (): void => {
    setOpenTip(true);
  };

  const handleHideCopy = (): void => {
    setShowCopy(false);
  };

  const handleShowCopy = (): void => {
    setShowCopy(true);
  };

  const description =
    endpoint.endpointType === EndpointType.HttpParameter
      ? t('endpoint_guide_dialog.description_parameter')
      : t('endpoint_guide_dialog.description_header');
  const sampleHeader = `curl --location \\
      --request POST '${endpoint.url}' \\
      --header 'Content-Type: application/json' \\`;
  const sampleData = `
      --data-raw '{
        "jsonrpc":"2.0",
        "method":"eth_blockNumber",
        "params":[],
        "id":1
      }'`;
  const auth = `
      --header 'x-api-key: ${apiKey}' \\`;
  const codeSample =
    endpoint.endpointType === EndpointType.HttpParameter
      ? `${sampleHeader}${sampleData}`
      : `${sampleHeader}${auth}${sampleData}`;

  return (
    <Dialog open={open} data-testid="endpoint-guide-dialog">
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {t('endpoint_guide_dialog.title')}
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {description}
        </Typography>
      </DialogTitle>
      <div
        className={classes.content}
        data-testid="code-box"
        onMouseEnter={handleShowCopy}
        onMouseLeave={handleHideCopy}
      >
        <pre className={classes.pre}>
          <code className={classes.code}>{codeSample}</code>
        </pre>
        <Tooltip
          arrow
          open={openTip}
          onClose={handleCloseTip}
          placement="top"
          title={t('copied') || 'Copied!'}
          className={classes.copy}
        >
          <span>
            <CopyToClipBoard text={codeSample}>
              <IconButton
                disabled={!showCopy}
                onClick={handleClickButton}
                className={classes.copyIcon}
                data-testid="copy-button"
              >
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipBoard>
          </span>
        </Tooltip>
      </div>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose} data-testid="ok-button">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndpointGuideDialog;
