import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
  },
  top: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 60,
      marginRight: theme.spacing(1),
    },
  },
  label: {},
  message: {
    marginTop: theme.spacing(1),
  },
  bottom: {
    borderTop: `1px solid ${theme.colors.gainsboro}`,
    padding: theme.spacing(1, 2),
    textAlign: 'right',
  },
  button: {
    marginLeft: theme.spacing(2),
    color: theme.colors.dodgerBlue,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    '& p': {
      lineHeight: '29px',
    },
  },
  buttonIcon: {},
  chipRegistered: {
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.colors.fruitSalad}!important`,
  },
  link: {
    textDecoration: 'none',
  },
}));

export { useStyles };
