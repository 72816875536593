import React, { useMemo } from 'react';

import { Box, Divider } from '@mui/material';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';

import LanguageMenu from '../language-menu';
import NavigatorItem, { NavigatorItemConfig } from './navigator-tem';

import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  config: NavigatorItemConfig[];
  isTemporarySideBar?: boolean;
  onCloseDrawer?: () => void;
}

const NavigatorMenu: React.FC<Props> = (props) => {
  const { config, isTemporarySideBar, onCloseDrawer = () => {} } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const renderNavigatorItems = useMemo(
    () => config.map((item, index) => <NavigatorItem item={item} key={index} />),
    [config],
  );

  const closeDrawer = () => {
    if (isTemporarySideBar) onCloseDrawer();
  };

  return (
    <List sx={{ paddingBottom: 0 }}>
      <Toolbar />
      <Box
        className={classes.sideBar}
        sx={{ height: !isTemporarySideBar ? 'calc(100vh - 104px)' : 'unset' }}
      >
        <Box className={classes.navigatorItem} onClick={closeDrawer}>
          {renderNavigatorItems}
        </Box>
        <Divider sx={{ margin: '0 16px 16px 20px' }} />
        <LanguageMenu />
      </Box>
    </List>
  );
};

export default NavigatorMenu;
