import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
const defaultOptions = { context: { apiName: 'endpoint-service' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CreateEndpointInput = {
  /** endpoint type */
  endpointType: EndpointType;
  /** network type */
  networkType: NetworkType;
};

export type DeleteEndpointInput = {
  /** endpoint id */
  endpointId: Scalars['String'];
};

export type Endpoint = {
  __typename?: 'Endpoint';
  /** Endpoint Id */
  endpointId: Scalars['String'];
  /** Endpoint Type */
  endpointType: EndpointType;
  /** Endpoint name */
  name: Scalars['String'];
  /** Network Type */
  networkType: NetworkType;
  /** Endpoint status */
  status: EndpointStatus;
  /** Endpoint URL */
  url: Scalars['String'];
};

export type EndpointList = {
  __typename?: 'EndpointList';
  /** API Key */
  apiKey: Scalars['String'];
  /** endpoints */
  endpoints: Array<Endpoint>;
  /** total request count */
  requestCount: Scalars['Float'];
};

export enum EndpointStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum EndpointType {
  HttpHeader = 'HTTP_HEADER',
  HttpParameter = 'HTTP_PARAMETER',
  WsHeader = 'WS_HEADER',
  WsParameter = 'WS_PARAMETER',
}

export type EndpointWithKey = {
  __typename?: 'EndpointWithKey';
  /** API Key */
  apiKey: Scalars['String'];
  /** endpoint */
  endpoint: Endpoint;
};

export type GetEndpointInfoInput = {
  /** endpoint id */
  endpointId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermOfService: Scalars['Boolean'];
  changePlan: Plan;
  createEndpoint: EndpointWithKey;
  deleteEndpoint: Endpoint;
  updateEndpoint: Endpoint;
};

export enum NetworkType {
  Joc = 'JOC',
  Mainnet = 'MAINNET',
  Polygon = 'POLYGON',
}

export enum Period {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type Plan = {
  __typename?: 'Plan';
  /** endpoint limit count */
  endpointLimitCount: Scalars['Float'];
  /** plan name */
  plan: PlanName;
  /** request limit count */
  requestLimitCount: Scalars['Float'];
  /** request limit period */
  requestLimitPeriod: Period;
};

export enum PlanName {
  Growth = 'GROWTH',
  Large = 'LARGE',
  Small = 'SMALL',
  Trial = 'TRIAL',
}

export type Query = {
  __typename?: 'Query';
  getCurrentPlan: Plan;
  getEndpointInfo: EndpointWithKey;
  getUserStatus: UserStatus;
  listEndpoints: EndpointList;
};

export type UpdateEndpointInput = {
  /** endpoint id */
  endpointId: Scalars['String'];
  /** updating name */
  updateName: Scalars['String'];
};

export type UserStatus = {
  __typename?: 'UserStatus';
  /** Accept term of service */
  isAcceptTermOfService: Scalars['Boolean'];
  /** Firebase uid */
  uid: Scalars['String'];
};

export type AcceptEndpointTermOfServiceMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptEndpointTermOfServiceMutation = {
  __typename?: 'Mutation';
  acceptEndpointTermOfService: boolean;
};

export type CreateEndpointMutationVariables = Exact<{
  input: CreateEndpointInput;
}>;

export type CreateEndpointMutation = {
  __typename?: 'Mutation';
  createEndpoint: {
    __typename?: 'EndpointWithKey';
    apiKey: string;
    endpoint: {
      __typename?: 'Endpoint';
      endpointId: string;
      endpointType: EndpointType;
      networkType: NetworkType;
      url: string;
      name: string;
      status: EndpointStatus;
    };
  };
};

export type DeleteEndpointMutationVariables = Exact<{
  input: DeleteEndpointInput;
}>;

export type DeleteEndpointMutation = {
  __typename?: 'Mutation';
  deleteEndpoint: {
    __typename?: 'Endpoint';
    endpointId: string;
    endpointType: EndpointType;
    networkType: NetworkType;
    url: string;
    name: string;
    status: EndpointStatus;
  };
};

export type UpdateEndpointMutationVariables = Exact<{
  input: UpdateEndpointInput;
}>;

export type UpdateEndpointMutation = {
  __typename?: 'Mutation';
  updateEndpoint: {
    __typename?: 'Endpoint';
    endpointId: string;
    endpointType: EndpointType;
    networkType: NetworkType;
    url: string;
    name: string;
    status: EndpointStatus;
  };
};

export type GetCurrentPlanQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentPlanQuery = {
  __typename?: 'Query';
  getCurrentPlan: {
    __typename?: 'Plan';
    plan: PlanName;
    requestLimitCount: number;
    requestLimitPeriod: Period;
    endpointLimitCount: number;
  };
};

export type GetEndpointInfoQueryVariables = Exact<{
  input: GetEndpointInfoInput;
}>;

export type GetEndpointInfoQuery = {
  __typename?: 'Query';
  getEndpointInfo: {
    __typename?: 'EndpointWithKey';
    apiKey: string;
    endpoint: {
      __typename?: 'Endpoint';
      endpointId: string;
      endpointType: EndpointType;
      networkType: NetworkType;
      url: string;
      name: string;
      status: EndpointStatus;
    };
  };
};

export type GetEndpointSerivceUserStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetEndpointSerivceUserStatusQuery = {
  __typename?: 'Query';
  getEndpointSerivceUserStatus: {
    __typename?: 'UserStatus';
    uid: string;
    isAcceptTermOfService: boolean;
  };
};

export type ListEndpointsQueryVariables = Exact<{ [key: string]: never }>;

export type ListEndpointsQuery = {
  __typename?: 'Query';
  endpointServiceListEndpoints: {
    __typename?: 'EndpointList';
    apiKey: string;
    requestCount: number;
    endpoints: Array<{
      __typename?: 'Endpoint';
      endpointId: string;
      endpointType: EndpointType;
      networkType: NetworkType;
      url: string;
      name: string;
      status: EndpointStatus;
    }>;
  };
};

export const AcceptEndpointTermOfServiceDocument = gql`
  mutation acceptEndpointTermOfService {
    acceptEndpointTermOfService: acceptTermOfService
  }
`;
export function useAcceptEndpointTermOfServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptEndpointTermOfServiceMutation,
    AcceptEndpointTermOfServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptEndpointTermOfServiceMutation,
    AcceptEndpointTermOfServiceMutationVariables
  >(AcceptEndpointTermOfServiceDocument, options);
}

export const CreateEndpointDocument = gql`
  mutation createEndpoint($input: CreateEndpointInput!) {
    createEndpoint(input: $input) {
      apiKey
      endpoint {
        endpointId
        endpointType
        networkType
        url
        name
        status
      }
    }
  }
`;

export function useCreateEndpointMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEndpointMutation, CreateEndpointMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEndpointMutation, CreateEndpointMutationVariables>(
    CreateEndpointDocument,
    options,
  );
}

export const DeleteEndpointDocument = gql`
  mutation deleteEndpoint($input: DeleteEndpointInput!) {
    deleteEndpoint(input: $input) {
      endpointId
      endpointType
      networkType
      url
      name
      status
    }
  }
`;

export function useDeleteEndpointMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEndpointMutation, DeleteEndpointMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEndpointMutation, DeleteEndpointMutationVariables>(
    DeleteEndpointDocument,
    options,
  );
}

export const UpdateEndpointDocument = gql`
  mutation updateEndpoint($input: UpdateEndpointInput!) {
    updateEndpoint(input: $input) {
      endpointId
      endpointType
      networkType
      url
      name
      status
    }
  }
`;

export function useUpdateEndpointMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEndpointMutation, UpdateEndpointMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEndpointMutation, UpdateEndpointMutationVariables>(
    UpdateEndpointDocument,
    options,
  );
}

export const GetCurrentPlanDocument = gql`
  query getCurrentPlan {
    getCurrentPlan {
      plan
      requestLimitCount
      requestLimitPeriod
      endpointLimitCount
    }
  }
`;

export function useGetCurrentPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentPlanQuery, GetCurrentPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentPlanQuery, GetCurrentPlanQueryVariables>(
    GetCurrentPlanDocument,
    options,
  );
}

export const GetEndpointInfoDocument = gql`
  query getEndpointInfo($input: GetEndpointInfoInput!) {
    getEndpointInfo(input: $input) {
      apiKey
      endpoint {
        endpointId
        endpointType
        networkType
        url
        name
        status
      }
    }
  }
`;

export function useGetEndpointInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetEndpointInfoQuery, GetEndpointInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEndpointInfoQuery, GetEndpointInfoQueryVariables>(
    GetEndpointInfoDocument,
    options,
  );
}

export const GetEndpointSerivceUserStatusDocument = gql`
  query getEndpointSerivceUserStatus {
    getEndpointSerivceUserStatus: getUserStatus {
      uid
      isAcceptTermOfService
    }
  }
`;

export function useGetEndpointSerivceUserStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEndpointSerivceUserStatusQuery,
    GetEndpointSerivceUserStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEndpointSerivceUserStatusQuery,
    GetEndpointSerivceUserStatusQueryVariables
  >(GetEndpointSerivceUserStatusDocument, options);
}

export const ListEndpointsDocument = gql`
  query listEndpoints {
    endpointServiceListEndpoints: listEndpoints {
      apiKey
      requestCount
      endpoints {
        endpointId
        endpointType
        networkType
        url
        name
        status
      }
    }
  }
`;
export function useListEndpointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListEndpointsQuery, ListEndpointsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListEndpointsQuery, ListEndpointsQueryVariables>(
    ListEndpointsDocument,
    options,
  );
}
