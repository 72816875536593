import React, { useCallback, useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// Styles
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import DeleteEndpointDialog from './delete-endpoint-dialog';
import EndpointGuideDialog from './guide-dialog';
import UpdateEndpointDialog from './update-endpoint-dialog';
import UserLayout from '~/components/app-layout';
import CopyClipboard from '~/components/copy-clipboard';
import LinkButton from '~/components/link-button';
import Loading from '~/components/loading';
import { AppRoutesEnum } from '~/enums/route.enum';
import { withApolloClient } from '~/graphql/client';
import { EndpointStatus, useGetEndpointInfoQuery } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import {
  getEndpointStatusI18n,
  getEndpointTypeI18n,
  getNetworkTypeI18n,
} from '~/utils/render.util';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const ServiceDetail = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteEndpointDialog, setOpenDeleteEndpointDialog] = useState(false);
  const [openUpdateEndpointDialog, setOpenUpdateEndpointDialog] = useState(false);
  const [openGuideDialog, setOpenGuideDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const { id = '' } = useParams();
  const {
    data: endpointInfoData,
    loading: endpointInfoLoading,
    error: endpointInfoError,
  } = useGetEndpointInfoQuery({
    variables: {
      input: {
        endpointId: id.toString(),
      },
    },
  });

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(undefined);
  }, []);

  const handleOpenUpdateEndpointDialog = useCallback(() => {
    setMenuAnchorEl(undefined);
    setOpenUpdateEndpointDialog(true);
  }, []);

  const handleCloseUpdateEndpointDialog = useCallback(() => {
    setOpenUpdateEndpointDialog(false);
  }, []);

  const handleOpenDeleteEndpointDialog = useCallback(() => {
    setMenuAnchorEl(undefined);
    setOpenDeleteEndpointDialog(true);
  }, []);

  const handleCloseDeleteEndpointDialog = useCallback(() => {
    setOpenDeleteEndpointDialog(false);
  }, []);

  const handleOpenGuideDialog = useCallback(() => {
    setOpenGuideDialog(true);
  }, []);

  const handleCloseGuideDialog = useCallback(() => {
    setOpenGuideDialog(false);
  }, []);

  useEffect(() => {
    if (endpointInfoError) {
      enqueueSnackbar(endpointInfoError.message, { variant: 'error' });
    }
  }, [endpointInfoError, enqueueSnackbar]);

  if (endpointInfoLoading) {
    return (
      <UserLayout>
        <Loading />
      </UserLayout>
    );
  }

  if (!endpointInfoData?.getEndpointInfo) {
    return (
      <UserLayout>
        <Typography variant="h5">{t('endpoint_service_page.service_detail_title')}</Typography>
      </UserLayout>
    );
  }

  return (
    <UserLayout>
      <Typography variant="h5">{t('endpoint_service_page.service_detail_title')}</Typography>
      <Box className={classes.root} data-testid="endpoint-service">
        <Box className={classes.header}>
          <Typography variant="h6">{t('endpoint')}</Typography>
          {endpointInfoData.getEndpointInfo.endpoint.status === EndpointStatus.Active && (
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
          )}
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleOpenUpdateEndpointDialog}>{t('edit')}</MenuItem>
            <MenuItem onClick={handleOpenDeleteEndpointDialog}>{t('delete')}</MenuItem>
          </Menu>
        </Box>
        <TableContainer>
          <Table className={classes.table}>
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  <Typography variant="body1" className={classes.content}>
                    {t('name')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant="body1" className={classes.content}>
                    {endpointInfoData.getEndpointInfo.endpoint.name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  <Typography variant="body1" className={classes.content}>
                    {t('status')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant="body1" className={classes.content}>
                    {getEndpointStatusI18n(t, endpointInfoData.getEndpointInfo.endpoint.status)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  <Typography variant="body1" className={classes.content}>
                    {t('endpoint_type')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant="body1" className={classes.content}>
                    {getEndpointTypeI18n(t, endpointInfoData.getEndpointInfo.endpoint.endpointType)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  <Typography variant="body1" className={classes.content}>
                    {t('network_type')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant="body1" className={classes.content}>
                    {getNetworkTypeI18n(t, endpointInfoData.getEndpointInfo.endpoint.networkType)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  <Typography variant="body1" className={classes.content}>
                    {t('url')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <div className={classes.flex}>
                    <Typography variant="body1" className={classes.content}>
                      {endpointInfoData.getEndpointInfo.endpoint.url}
                    </Typography>
                    <CopyClipboard
                      value={endpointInfoData.getEndpointInfo.endpoint.url}
                    ></CopyClipboard>
                  </div>
                </TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  <Typography variant="body1" className={classes.content}>
                    {t('api_key')}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <div className={classes.flex}>
                    <Typography variant="body1" className={classes.content}>
                      {endpointInfoData.getEndpointInfo.apiKey}
                    </Typography>
                    <CopyClipboard value={endpointInfoData.getEndpointInfo.apiKey}></CopyClipboard>
                  </div>
                </TableCell>
                <TableCell className={classes.cell} align="right" sx={{ paddingRight: 3 }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={handleOpenGuideDialog}
                  >
                    {t('how_to_use')}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <LinkButton
        route={AppRoutesEnum.ENDPOINT_SERVICE}
        color="primary"
        variant="outlined"
        className={classes.backButton}
        startIcon={<ArrowBackIcon />}
        text={t('back')}
      />
      <DeleteEndpointDialog
        endpointId={id.toString()}
        open={openDeleteEndpointDialog}
        onClose={handleCloseDeleteEndpointDialog}
      />
      <UpdateEndpointDialog
        endpoint={endpointInfoData.getEndpointInfo.endpoint}
        open={openUpdateEndpointDialog}
        onClose={handleCloseUpdateEndpointDialog}
      />
      <EndpointGuideDialog
        apiKey={endpointInfoData.getEndpointInfo.apiKey}
        endpoint={endpointInfoData.getEndpointInfo.endpoint}
        open={openGuideDialog}
        onClose={handleCloseGuideDialog}
      />
    </UserLayout>
  );
};

export default compose(withApolloClient)(ServiceDetail);
