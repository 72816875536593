import { useCallback } from 'react';

import { FC } from 'react';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

interface Props extends ButtonProps {
  dataTestId?: string;
  text: string;
  route: string;
}
const LinkButton: FC<Props> = ({
  className,
  color,
  startIcon,
  size,
  dataTestId,
  text,
  route,
  disabled,
  variant,
}) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(route);
  }, []);

  return (
    <Button
      onClick={handleNavigate}
      className={className}
      data-testid={dataTestId}
      color={color}
      startIcon={startIcon}
      size={size}
      disabled={disabled}
      variant={variant}
    >
      {text}
    </Button>
  );
};

export default LinkButton;
