import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';

import { AppRoutesEnum } from '~/enums/route.enum';

export const useLogout = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const logout = useCallback(async () => {
    await firebase.auth().signOut();
    client.clearStore();
    navigate(AppRoutesEnum.AUTH);
  }, [client, navigate]);

  return logout;
};
