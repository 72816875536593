import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  box: {
    marginTop: theme.spacing(3),
  },
  description: {
    marginTop: theme.spacing(1),
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3),
    },
    gap: theme.spacing(1),
    '& > :last-child': {
      minWidth: 180,
    },
  },
  itemText: {
    fontWeight: 'bold!important',
  },
  backButton: {
    marginTop: theme.spacing(1),
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export { useStyles };
