import React, { useEffect } from 'react';

import { RouteObject, useLocation, useRoutes } from 'react-router-dom';

import AboutPage from './pages/About';
import AuthPage from './pages/Auth';
import DeveloperProgram from './pages/DeveloperProgram';
import EndPointService from './pages/EndPointService';
import HomePage from './pages/Home';
import NotFoundPage from './pages/NotFound';

import PrivateWrapper from '~/contexts/PrivateWrapper';
import { AppRoutesEnum } from '~/enums/route.enum';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';
import ServiceDetail from '~/pages/EndPointService/endpoint-service-detail';

export const routes: RouteObject[] = [
  {
    path: AppRoutesEnum.ABOUT,
    element: <AboutPage />,
  },
  {
    path: AppRoutesEnum.AUTH,
    element: (
      <WithFirebaseUser>
        <AuthPage />
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRoutesEnum.HOME,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },
  {
    path: AppRoutesEnum.ENDPOINT_SERVICE,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <EndPointService />,
      },
      {
        path: AppRoutesEnum.ENDPOINT_SERVICE_DETAIL.replace(/\/:id\//g, ''),
        element: <ServiceDetail />,
      },
    ],
  },
  {
    path: AppRoutesEnum.DEVELOPMENT_PROGRAM.replace(/\//g, ''),
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <DeveloperProgram />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routes);
};
