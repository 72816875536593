import { IsNotEmpty } from 'class-validator';
import { validateObject } from '~/common/validator/class-validator-helper';

export class FormValues {
  @IsNotEmpty({
    context: {
      i18n: {
        key: 'form_validation.isNotEmpty',
      },
    },
  })
  updateName?: string;
}

export const validateForm = (t: any) => async (values: any) =>
  await validateObject(t, Object.assign(new FormValues(), values));
