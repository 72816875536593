import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  description: {
    marginTop: theme.spacing(1),
  },
  container: {
    width: '100%',
  },
  content: {
    width: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      marginTop: 30,
    },
    maxWidth: 890,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  term: {
    padding: theme.spacing(4),
  },
  checkbox: {
    display: 'block',
    margin: theme.spacing(2, 0),
  },
  wrapCheckBox: {
    textAlign: 'center',
    padding: theme.spacing(0, 4, 4, 4),
  },
  wrapButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
    flexWrap: 'wrap-reverse',
  },
}));

export { useStyles };
