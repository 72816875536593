import React, { useCallback, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import FirebaseAuthLocalized from './firebase-auth-localized';
import HowToRegisterNewAccountDialog from './how-to-register-new-account-dialog';

import AuthLayout from '~/components/auth-layout';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const AuthPage = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const [openGuideDialog, setOpenGuideDialog] = useState(false);
  const location = useLocation();
  const firebaseUser = useFirebaseUser();
  const onOpenGuideDialog = useCallback(() => {
    setOpenGuideDialog(true);
  }, []);

  const onCloseGuideDialog = useCallback(() => {
    setOpenGuideDialog(false);
  }, []);

  const handleLogout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  const query = new URLSearchParams(location.search);
  const config: firebaseui.auth.Config = useMemo(
    () =>
      query.get('redirectTo')
        ? {
            signInSuccessUrl: query.get('redirectTo') || undefined,
          }
        : {},
    [query],
  );

  return (
    <AuthLayout>
      <Paper className={classes.paper}>
        <div>
          {!firebaseUser && (
            <Typography variant="overline" className={classes.title}>
              {t('sign_in')}
            </Typography>
          )}
        </div>
        <div className={classes.logo}>
          <img src="/images/logo.svg" alt="" />
        </div>
        <div>
          {firebaseUser && !firebaseUser.emailVerified ? (
            <>
              <Typography variant="body2" className={classes.emailConfirmationWaring}>
                {t('email_confirmation_warning')}
              </Typography>
              <Button
                className={classes.btnLogout}
                size="small"
                variant="contained"
                onClick={handleLogout}
              >
                {t('logout')}
              </Button>
            </>
          ) : (
            <FirebaseAuthLocalized language={i18n.language} config={config} />
          )}
          <Typography
            variant="body2"
            color="primary"
            className={classes.guide}
            onClick={onOpenGuideDialog}
          >
            {t('how_to_register_a_new_account')}
          </Typography>
        </div>
      </Paper>
      <HowToRegisterNewAccountDialog open={openGuideDialog} onClose={onCloseGuideDialog} />
    </AuthLayout>
  );
};

export default AuthPage;
