import React, { SyntheticEvent, useCallback, useState } from 'react';
// Components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

// Styles
import LinkButton from '~/components/link-button';
import { termConfig } from '~/configs/term.config';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useAcceptEndpointTermOfServiceMutation } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  onAccept?: () => void;
}

const Terms = (props: Props) => {
  const { onAccept } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();
  const [acceptEndpointTermOfService, { loading: loadingAcceptTerm }] =
    useAcceptEndpointTermOfServiceMutation();
  const [agree, setAgree] = useState(false);
  const [loadingTerms, setLoadingTerms] = useState(true);

  const onRegister = useCallback(async () => {
    await acceptEndpointTermOfService();
    onAccept?.();
  }, [acceptEndpointTermOfService, onAccept]);

  const onCheck = useCallback((event: SyntheticEvent<Element, Event>, checked: boolean) => {
    setAgree(checked);
  }, []);

  const onTermsLoaded = useCallback(() => {
    setLoadingTerms(false);
  }, []);

  return (
    <div className={classes.container}>
      <Typography variant="h5">{t('endpoint_service_page.term_title')}</Typography>
      <Typography variant="body1" color="textSecondary" className={classes.description}>
        {t('endpoint_service_page.term_description')}
      </Typography>
      <div className={classes.content}>
        <div className={classes.term}>
          <iframe
            src={i18n.language === 'ja' ? termConfig.termOfUseJa : termConfig.termOfUseEn}
            width="100%"
            height={400}
            frameBorder="0"
            hidden={loadingTerms}
            onLoad={onTermsLoaded}
          />
          {loadingTerms && <Skeleton variant="rectangular" width="100%" height={400} />}
        </div>
      </div>
      <div className={classes.wrapCheckBox}>
        <div className={classes.checkbox}>
          <FormControlLabel
            control={<Checkbox />}
            checked={agree}
            label={t('terms_of_use.agree_the_terms_of_use')}
            onChange={onCheck}
          />
        </div>
        <div className={classes.wrapButtons}>
          <LinkButton
            route={AppRoutesEnum.HOME}
            color="primary"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            dataTestId="exit-button"
            text={t('back_home')}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            onClick={onRegister}
            disabled={loadingAcceptTerm || !agree}
            endIcon={loadingAcceptTerm && <CircularProgress size={20} color="inherit" />}
          >
            {t('register')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Terms;
