import { FC, PropsWithChildren, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Terms from './terms';
import UserLayout from '~/components/app-layout';
import { useGetEndpointSerivceUserStatusQuery } from '~/graphql/endpoint-service/types';

const withAcceptTermsGuard =
  <P,>(Component: any) =>
  (props: P) => {
    const {
      data: userStatusData,
      loading: getUserStatusLoading,
      refetch: refetchAcceptTerms,
    } = useGetEndpointSerivceUserStatusQuery({
      fetchPolicy: 'cache-and-network',
    });

    const onAcceptTerms = useCallback(() => {
      refetchAcceptTerms();
    }, [refetchAcceptTerms]);

    if (getUserStatusLoading) {
      return (
        <UserLayout>
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              left: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        </UserLayout>
      );
    }

    if (!userStatusData?.getEndpointSerivceUserStatus.isAcceptTermOfService) {
      return (
        <UserLayout>
          <Terms onAccept={onAcceptTerms} />
        </UserLayout>
      );
    }
    return <Component {...props} />;
  };

export default withAcceptTermsGuard;
