import React, { useCallback, useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const LanguageMenu: React.FC<Props> = (props) => {
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();

  const LANGS = useMemo(
    () => ({
      en: t('english'),
      ja: t('japanese'),
    }),
    [t],
  );

  const onCloseLanguageMenu = useCallback(() => {
    setLanguageMenuAnchorEl(null);
  }, [setLanguageMenuAnchorEl]);

  const onOpenLanguageMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setLanguageMenuAnchorEl(event.currentTarget);
    },
    [setLanguageMenuAnchorEl],
  );

  const onChangeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      onCloseLanguageMenu();
    },
    [i18n, onCloseLanguageMenu],
  );

  const onChangeLanguageEn = useCallback(() => {
    onChangeLanguage('en');
  }, []);

  const onChangeLanguageJa = useCallback(() => {
    onChangeLanguage('ja');
  }, []);

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'flex-start' }}
        onClick={onOpenLanguageMenu}
        color="inherit"
        className={classes.languageMenu}
      >
        <Box sx={{ display: 'flex', paddingLeft: '8px', textTransform: 'capitalize' }}>
          <PublicIcon />
          <Typography>{LANGS[i18n.language as 'en' | 'ja']}</Typography>
        </Box>
        <ExpandMoreIcon />
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(languageMenuAnchorEl)}
        onClose={onCloseLanguageMenu}
        anchorEl={languageMenuAnchorEl}
        classes={{ paper: classes.paper }}
      >
        <MenuItem selected={i18n.language === 'en'} onClick={onChangeLanguageEn}>
          {t('english')}
        </MenuItem>
        <MenuItem selected={i18n.language === 'ja'} onClick={onChangeLanguageJa}>
          {t('japanese')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageMenu;
