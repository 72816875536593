import React, { useCallback, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
// Components
import AddEndpointDialog from './add-endpoint-dialog';
import EndpointRow from './endpoint-row';
// Styles
import { Endpoint } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  endpoints?: Endpoint[];
  loading?: boolean;
}

const EndpointsListSection: React.FC<Props> = (props) => {
  const { endpoints, loading } = props;
  const { classes, cx } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const [openAddEthereumAddressDialog, setOpenAddEthereumAddressDialog] = useState(false);
  const onOpenAddEthereumAddressDialog = useCallback(() => {
    setOpenAddEthereumAddressDialog(true);
  }, []);
  const onCloseAddEthereumAddressDialog = useCallback(() => {
    setOpenAddEthereumAddressDialog(false);
  }, []);

  const renderData = useMemo(() => {
    if (endpoints?.length) {
      return endpoints.map((endpoint, index) => <EndpointRow key={index} endpoint={endpoint} />);
    }
    return (
      <TableRow>
        <TableCell className={classes.cell} colSpan={4}>
          <Typography variant="body1">{t('no_registration')}</Typography>
        </TableCell>
      </TableRow>
    );
  }, [classes, endpoints, t]);

  return (
    <div className={cx(classes.root, classes.width100)} data-testid="endpoint-list-section">
      <AddEndpointDialog
        open={openAddEthereumAddressDialog}
        onClose={onCloseAddEthereumAddressDialog}
      />
      <div className={cx(classes.line, classes.topLine)}>
        <Typography variant="h6">{t('endpoint_service_page.endpoint_list')}</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={onOpenAddEthereumAddressDialog}
          disabled={loading}
          startIcon={<AddIcon />}
          size="small"
        >
          {t('add')}
        </Button>
      </div>
      {loading ? (
        <div className={classes.line}>
          <Skeleton width="100%"></Skeleton>
        </div>
      ) : (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
                  {t('status')}
                </TableCell>
                <TableCell className={classes.cell}>{t('name')}</TableCell>
                <TableCell className={classes.cell}>{t('network_type')}</TableCell>
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderData}</TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default EndpointsListSection;
