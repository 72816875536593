import { useTranslation } from 'react-i18next';
import Error from '~/components/error';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return <Error code={404} message={t('error_page.404_message')} />;
};

export default NotFoundPage;
