import { NoEncryption } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      marginTop: 30,
    },
  },
  description: {
    marginTop: theme.spacing(1),
  },
  lineBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3),
    },
  },
  grid: {
    marginTop: theme.spacing(3),
  },
  block: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
    padding: theme.spacing(2),
    display: 'flex',
    '& > div': {
      flexGrow: 1,
      flexShink: 1,
      flexBasis: 0,
      textAlign: 'center',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  flexBlock: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: 'flex',
    '& > div': {
      flexGrow: 1,
      flexShink: 1,
      flexBasis: 0,
      textAlign: 'center',
      '& > div': {
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  blockTitle: {
    paddingBottom: theme.spacing(1),
  },
  flexBlockTitle: {
    padding: theme.spacing(1),
  },
  backButton: {
    marginTop: theme.spacing(4),
  },
  buttonHelp: {
    textTransform: 'none',
    [theme.breakpoints.between('lg', 1570)]: {
      display: 'none',
    },
  },
}));

export { useStyles };
