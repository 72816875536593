import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  contentWrapper: {
    padding: theme.spacing(2),
    width: '100%',
  },
  content: {
    margin: 'auto',
    maxWidth: 520,
    width: '100%',
  },
  language: {
    width: 155,
    marginTop: 10,
    float: 'right',
    border: `1px solid ${theme.colors.gainsboro}`,
    borderRadius: '4px',
  },
  languageMenu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: theme.colors.nightRider,
    '.MuiTypography-root': {
      marginLeft: '17px',
    },
    '.MuiButtonBase-root': {
      padding: '0!important',
    },
  },
}));

export { useStyles };
