import React, { useEffect } from 'react';

// components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import LinkButton from '~/components/link-button';
import { AppRoutesEnum } from '~/enums/route.enum';
import { useGetDevProgramMemberStatusQuery } from '~/graphql/development-program/types';
import { StyledComponentProps } from '~/types/material-ui';
import { getDevProgramMemberStatusI18n } from '~/utils/render.util';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

export default function DevelopmentResourceSection(props: Props) {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const { data: devProgramMemberStatusData, loading: getMemberStatusLoading } =
    useGetDevProgramMemberStatusQuery({
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    document.title = t('development_program.development_program_title');
  }, [t]);

  return (
    <div style={{ width: '100%' }} data-testid="development-program-resource">
      <Typography variant="h5">{t('development_program_page.section_title')}</Typography>
      <Typography variant="body1" color="textSecondary" className={classes.description}>
        {t('development_program_page.description')}
      </Typography>
      <div className={classes.box}>
        <div className={classes.line}>
          <Box display="flex" alignItems="center" paddingTop={1} paddingBottom={1}>
            <Typography variant="body1">
              {getDevProgramMemberStatusI18n(
                t,
                devProgramMemberStatusData?.getDevProgramMemberStatus?.status,
              )}
            </Typography>
            <IconButton color="inherit" target="_blank" href="/#" disabled>
              <HelpIcon />
            </IconButton>
          </Box>
          <LinkButton
            route={AppRoutesEnum.DEVELOPMENT_PROGRAM}
            color="primary"
            variant="contained"
            size="large"
            disabled
            text={t('change_plan')}
          />
        </div>
        <div className={classes.line}>
          <Typography className={classes.itemText}>{t('download_sdk')}</Typography>
          <Button
            color="primary"
            variant="contained"
            size="small"
            href="https://gusdk-docs.gu.net"
            target="_blank"
          >
            {t('detail')} &gt;&gt;
          </Button>
        </div>
        <div className={classes.line}>
          <Typography className={classes.itemText}>{t('issue_endpoint')}</Typography>
          <LinkButton
            route={AppRoutesEnum.ENDPOINT_SERVICE}
            color="primary"
            variant="contained"
            size="small"
            text={t('start_issue')}
          />
        </div>
        <div className={classes.line}>
          <Typography className={classes.itemText}>{t('use_token')}</Typography>
          <LinkButton
            route={AppRoutesEnum.HOME}
            variant="contained"
            size="small"
            disabled
            text={`${t('detail')} >>`}
          />
        </div>
      </div>
      <LinkButton
        route={AppRoutesEnum.HOME}
        className={classes.backButton}
        color="primary"
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        size="large"
        data-testid="exit-button"
        text={t('back_home')}
      />
    </div>
  );
}
