import { TFunction } from 'i18next';
import { DevProgramMemberStatus } from '~/graphql/development-program/types';
import {
  EndpointStatus,
  EndpointType,
  NetworkType,
  Period,
  PlanName,
} from '~/graphql/endpoint-service/types';
import { SelectOption } from '~/types/view/common';

export const getEndpointTypeOptions = (t: any): Array<SelectOption> => [
  {
    value: EndpointType.HttpParameter,
    label: t('endpoint_type_enum.http_parameter'),
  },
  {
    value: EndpointType.HttpHeader,
    label: t('endpoint_type_enum.http_header'),
  },
];

export const getEndpointTypeI18n = (t: any, endpointType?: EndpointType) => {
  switch (endpointType) {
    case EndpointType.HttpHeader:
      return t('endpoint_type_enum.http_header');
    case EndpointType.HttpParameter:
      return t('endpoint_type_enum.http_parameter');
    case EndpointType.WsHeader:
      return t('endpoint_type_enum.ws_header');
    case EndpointType.WsParameter:
      return t('endpoint_type_enum.ws_parameter');
    default:
      return '-';
  }
};
export const getEndpointStatusI18n = (t: any, endpointStatus?: EndpointStatus) => {
  switch (endpointStatus) {
    case EndpointStatus.Active:
      return t('endpoint_status_enum.active');
    case EndpointStatus.Deleted:
      return t('endpoint_status_enum.deleted');
    default:
      return '-';
  }
};

export const getNetworkTypeOptions = (t: any): Array<SelectOption> => [
  {
    value: NetworkType.Joc,
    label: t('network_type_enum.joc'),
  },
];

export const getNetworkTypeI18n = (t: any, networkType?: NetworkType) => {
  switch (networkType) {
    case NetworkType.Joc:
      return t('network_type_enum.joc');
    case NetworkType.Mainnet:
      return t('network_type_enum.mainnet');
    case NetworkType.Polygon:
      return t('network_type_enum.polygon');
    default:
      return '-';
  }
};

export const getPeriodI18n = (t: any, period: Period) => {
  switch (period) {
    case Period.Day:
      return t('day');
    case Period.Month:
      return t('month');
    case Period.Week:
      return t('week');
    default:
      return '-';
  }
};

export const getEndpointPlanNameI18n = (t: any, plan?: PlanName) => {
  switch (plan) {
    case PlanName.Trial:
      return t('endpoint_plan_name_enum.trial');
    case PlanName.Small:
      return t('endpoint_plan_name_enum.small');
    case PlanName.Large:
      return t('endpoint_plan_name_enum.large');
    case PlanName.Growth:
      return t('endpoint_plan_name_enum.growth');
    default:
      return '-';
  }
};

export const getDevProgramMemberStatusI18n = (
  t: TFunction,
  devProgramMemberStatus?: DevProgramMemberStatus,
) => {
  switch (devProgramMemberStatus) {
    case DevProgramMemberStatus.Starter:
      return t('dev_program_member_status_enum.starter');
    case DevProgramMemberStatus.Profressional:
      return t('dev_program_member_status_enum.professional');
    case DevProgramMemberStatus.Business:
      return t('dev_program_member_status_enum.business');
    case DevProgramMemberStatus.Premium:
      return t('dev_program_member_status_enum.premium');
    case DevProgramMemberStatus.Enterprise:
      return t('dev_program_member_status_enum.enterprise');
    default:
      return '-';
  }
};
