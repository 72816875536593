import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${alpha(theme.colors.black, 0.12)}`,
  },
  line: {
    borderTop: `1px solid ${theme.colors.gainsboro}`,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      padding: theme.spacing(2, 3),
    },
  },
  topLine: {
    borderTop: 'none',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3),
    },
  },
  cell: {
    borderBottom: `1px solid ${theme.colors.gainsboro}`,
  },
  table: {
    borderTop: `1px solid ${theme.colors.gainsboro}`,
  },
  width100: {
    width: `100%`,
  },
}));

export { useStyles };
