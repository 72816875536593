import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 130,
  },
  languageMenu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: theme.colors.nightRider,
    '.MuiTypography-root': {
      marginLeft: '17px',
    },
    '.MuiButtonBase-root': {
      padding: '0!important',
    },
  },
}));

export { useStyles };
