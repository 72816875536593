import React, { useCallback, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderBar from './user-layout/header-bar';
import SideBar from './user-layout/side-bar';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const UserLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children } = props;
  const { classes, cx, theme } = useStyles(undefined, { props: { classes: props.classes } });
  const [openDrawer, setOpenDrawer] = useState(false);
  const isTemporarySideBar = useMediaQuery(theme.breakpoints.down('md'));

  const onOpenDrawer = useCallback(() => {
    setOpenDrawer(true);
  }, []);
  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <div className={classes.root} data-testid="layout">
      <HeaderBar isTemporarySideBar={isTemporarySideBar} onOpenDrawer={onOpenDrawer} />
      <SideBar
        isTemporarySideBar={isTemporarySideBar}
        openDrawer={openDrawer}
        onCloseDrawer={onCloseDrawer}
      />
      <div
        className={cx(classes.rightSide, isTemporarySideBar && classes.rightSideTemporarySideBar)}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
