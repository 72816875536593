import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
}

const CopyClipboard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [openTip, setOpenTip] = useState(false);

  const handleCloseTip = (): void => {
    setOpenTip(false);
  };

  const handleClickButton = (): void => {
    setOpenTip(true);
  };

  return (
    <Tooltip
      arrow
      open={openTip}
      onClose={handleCloseTip}
      placement="top"
      title={t('copied') || 'Copied!'}
    >
      <span>
        <CopyToClipBoard text={props.value}>
          <IconButton disabled={props.value === ''} onClick={handleClickButton}>
            <ContentCopyIcon />
          </IconButton>
        </CopyToClipBoard>
      </span>
    </Tooltip>
  );
};

export default CopyClipboard;
