import { useMemo } from 'react';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  split,
  TypePolicy,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import hoistNonReactStatics from 'hoist-non-react-statics';
import { useTranslation } from 'react-i18next';
import {
  Query as DevelopmmentProgramQuery,
  Mutation as DevelopmmentProgramMutation,
} from './development-program/types';
import { apolloLinkConfig } from '~/configs/apollo.link.config';
import { GraphqlServerEnum } from '~/enums/graphql-server.enum';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const customFieldPolicy: Required<TypePolicy>['fields'][string] = {
  keyArgs: (args, context) => {
    return (context.field?.alias?.value || '') + JSON.stringify(context.variables);
  },
};
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getMemberStatus: customFieldPolicy,
      } as Record<keyof DevelopmmentProgramQuery, Required<TypePolicy>['fields'][string]>,
    },
    Mutation: {
      fields: {
        acceptTermOfService: customFieldPolicy,
      } as Record<keyof DevelopmmentProgramMutation, Required<TypePolicy>['fields'][string]>,
    },
  },
});

export const withApolloClient = (ChildComponent: any) => {
  const WithApolloClientHOC = (props: any) => {
    const user = useFirebaseUser();
    const { i18n } = useTranslation();
    const client = useMemo(() => {
      const developmentProgramLink = createHttpLink({
        uri: apolloLinkConfig.developmentProgramLink,
      });

      const endpointServiceLink = createHttpLink({
        uri: apolloLinkConfig.endpointServiceLink,
      });

      const asyncAuthLink = setContext(async (request, prevContext) => {
        const token = user && (await user.getIdToken());
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
            'Accept-Language': i18n.language,
          },
        };
      });

      const errorLink = onError(({ graphQLErrors }) => {
        graphQLErrors?.forEach((err) => {
          const messageI18n = (err.extensions?.localizedMessages as Record<string, string>)?.[
            i18n.language
          ];
          if (messageI18n) {
            err.message = messageI18n;
          }
        });
      });

      const linkSwitch = split(
        ({ getContext }) => getContext().apiName === GraphqlServerEnum.DevelopmentProgram,
        developmentProgramLink,
        split(
          ({ getContext }) => getContext().apiName === GraphqlServerEnum.EndpointService,
          endpointServiceLink,
        ),
      );

      return new ApolloClient({
        link: asyncAuthLink.concat(from([errorLink, linkSwitch])),
        cache,
      });
    }, [user, i18n.language]);

    return (
      <ApolloProvider client={client}>
        <ChildComponent {...props} />
      </ApolloProvider>
    );
  };

  WithApolloClientHOC.displayName = 'WithApolloClientHOC';
  hoistNonReactStatics(WithApolloClientHOC, ChildComponent);

  return WithApolloClientHOC;
};
