import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'development-program' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum DevProgramMemberStatus {
  Business = 'BUSINESS',
  Enterprise = 'ENTERPRISE',
  Premium = 'PREMIUM',
  Profressional = 'PROFRESSIONAL',
  Starter = 'STARTER',
}

export type MemberStatus = {
  __typename?: 'MemberStatus';
  /** Account id */
  accountId: Scalars['String'];
  /** Accept term of service */
  isAcceptTermOfService: Scalars['Boolean'];
  /** Status */
  status: DevProgramMemberStatus;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermOfService: MemberStatus;
};

export type Query = {
  __typename?: 'Query';
  getMemberStatus?: Maybe<MemberStatus>;
};

export type AcceptDevProgramTermOfServiceMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptDevProgramTermOfServiceMutation = {
  __typename?: 'Mutation';
  acceptDevProgramTermOfService: {
    __typename?: 'MemberStatus';
    accountId: string;
    isAcceptTermOfService: boolean;
    status: DevProgramMemberStatus;
  };
};

export type GetDevProgramMemberStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetDevProgramMemberStatusQuery = {
  __typename?: 'Query';
  getDevProgramMemberStatus?: {
    __typename?: 'MemberStatus';
    accountId: string;
    isAcceptTermOfService: boolean;
    status: DevProgramMemberStatus;
  } | null;
};

export const AcceptDevProgramTermOfServiceDocument = gql`
  mutation acceptDevProgramTermOfService {
    acceptDevProgramTermOfService: acceptTermOfService {
      accountId
      isAcceptTermOfService
      status
    }
  }
`;
export type AcceptDevProgramTermOfServiceMutationFn = Apollo.MutationFunction<
  AcceptDevProgramTermOfServiceMutation,
  AcceptDevProgramTermOfServiceMutationVariables
>;

/**
 * __useAcceptDevProgramTermOfServiceMutation__
 *
 * To run a mutation, you first call `useAcceptDevProgramTermOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDevProgramTermOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDevProgramTermOfServiceMutation, { data, loading, error }] = useAcceptDevProgramTermOfServiceMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptDevProgramTermOfServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptDevProgramTermOfServiceMutation,
    AcceptDevProgramTermOfServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptDevProgramTermOfServiceMutation,
    AcceptDevProgramTermOfServiceMutationVariables
  >(AcceptDevProgramTermOfServiceDocument, options);
}
export type AcceptDevProgramTermOfServiceMutationHookResult = ReturnType<
  typeof useAcceptDevProgramTermOfServiceMutation
>;
export type AcceptDevProgramTermOfServiceMutationResult =
  Apollo.MutationResult<AcceptDevProgramTermOfServiceMutation>;
export type AcceptDevProgramTermOfServiceMutationOptions = Apollo.BaseMutationOptions<
  AcceptDevProgramTermOfServiceMutation,
  AcceptDevProgramTermOfServiceMutationVariables
>;
export const GetDevProgramMemberStatusDocument = gql`
  query getDevProgramMemberStatus {
    getDevProgramMemberStatus: getMemberStatus {
      accountId
      isAcceptTermOfService
      status
    }
  }
`;

/**
 * __useGetDevProgramMemberStatusQuery__
 *
 * To run a query within a React component, call `useGetDevProgramMemberStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevProgramMemberStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevProgramMemberStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDevProgramMemberStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDevProgramMemberStatusQuery,
    GetDevProgramMemberStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDevProgramMemberStatusQuery, GetDevProgramMemberStatusQueryVariables>(
    GetDevProgramMemberStatusDocument,
    options,
  );
}
export function useGetDevProgramMemberStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDevProgramMemberStatusQuery,
    GetDevProgramMemberStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDevProgramMemberStatusQuery,
    GetDevProgramMemberStatusQueryVariables
  >(GetDevProgramMemberStatusDocument, options);
}
export type GetDevProgramMemberStatusQueryHookResult = ReturnType<
  typeof useGetDevProgramMemberStatusQuery
>;
export type GetDevProgramMemberStatusLazyQueryHookResult = ReturnType<
  typeof useGetDevProgramMemberStatusLazyQuery
>;
export type GetDevProgramMemberStatusQueryResult = Apollo.QueryResult<
  GetDevProgramMemberStatusQuery,
  GetDevProgramMemberStatusQueryVariables
>;
