import { makeStyles } from 'tss-react/mui';
import { REGULAR } from '~/constants/font-weight';

const useStyles = makeStyles()((theme) => ({
  lastLogin: {
    lineHeight: '22px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontWeight: REGULAR,
  },
  description: {
    marginTop: theme.spacing(1),
  },
  cardContent: {
    padding: 24,
  },
  contentHeader: {
    fontSize: '20px!important',
    fontWeight: 500,
    color: '#333333',
  },
  contentText: {
    fontSize: 16,
    color: '#0000008A',
    fontWeight: 400,
    marginTop: '8px',
  },
}));

export { useStyles };
