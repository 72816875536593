import React, { useCallback } from 'react';
// Components
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// Types
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { initialValues, FormValues, validateForm } from './form';
import { GraphqlServerEnum } from '~/enums/graphql-server.enum';
import { useCreateEndpointMutation, ListEndpointsDocument } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { getEndpointTypeOptions, getNetworkTypeOptions } from '~/utils/render.util';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
}
const AddEndpointDialog: React.FC<Props> = (props) => {
  const { open } = props;
  return (
    <Dialog open={open} data-testid="add-endpoint-dialog">
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<Props> = (props) => {
  const { onClose } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createEndpoint, { loading: createEndpointLoading }] = useCreateEndpointMutation({
    refetchQueries: [
      { query: ListEndpointsDocument, context: { apiName: GraphqlServerEnum.EndpointService } },
    ],
  });
  const onSubmit = useCallback(
    async (values: any) => {
      try {
        await createEndpoint({
          variables: {
            input: values,
          },
        });
        onClose();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [createEndpoint, onClose, enqueueSnackbar],
  );
  const formik = useFormik<FormValues>({
    initialValues,
    validate: validateForm(t),
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <>
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid="close-button">
        <img src="/images/close_icon.svg" alt="" />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Typography variant="h5" color="primary" component="p">
            {t('add_endpoint_dialog.title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {t('add_endpoint_dialog.description')}
          </Typography>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="endpointType"
                select
                label={t('endpoint_type')}
                helperText={formik.touched && formik.errors.endpointType}
                error={Boolean(formik.touched && formik.errors.endpointType)}
                onChange={formik.handleChange}
                color="primary"
                value={formik.values.endpointType}
                variant="outlined"
                fullWidth
              >
                {getEndpointTypeOptions(t).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="networkType"
                select
                label={t('network_type')}
                helperText={formik.touched && formik.errors.networkType}
                error={Boolean(formik.touched && formik.errors.networkType)}
                onChange={formik.handleChange}
                color="primary"
                value={formik.values.networkType}
                variant="outlined"
                fullWidth
              >
                {getNetworkTypeOptions(t).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
            endIcon={createEndpointLoading && <CircularProgress size={20} color="inherit" />}
          >
            {t('add')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddEndpointDialog;
