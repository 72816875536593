import { relative } from 'path';
import { makeStyles } from 'tss-react/mui';
import { closeDialogButton } from '~/styles/common';

const useStyles = makeStyles()((theme) => ({
  closeIcon: {
    ...closeDialogButton(theme),
  },
  divider: {
    margin: theme.spacing(0, 3, 2, 3),
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    position: 'relative',
  },
  pre: {
    background: theme.colors.black,
    borderRadius: theme.spacing(0.5),
    overflowX: 'scroll',
    padding: theme.spacing(2),
    margin: 0,
  },
  code: {
    fontFamily: 'courier',
    color: theme.colors.white,
  },
  copy: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(4),
  },
  copyIcon: {
    color: theme.colors.white,
  },
}));

export { useStyles };
