import 'firebase/auth';

import React, { useCallback, useMemo } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpIcon from '@mui/icons-material/Help';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import firebase from 'firebase/app';

import UserPopover from './user-popover';

import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  onOpenDrawer: () => void;
}

const HeaderBar: React.FC<Props> = (props) => {
  const user = firebase.auth().currentUser;
  const { isTemporarySideBar, onOpenDrawer } = props;
  const { classes, cx } = useStyles(undefined, { props: { classes: props.classes } });
  const [userPopoverAnchorEl, setUserPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
  const onOpenUserPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setUserPopoverAnchorEl(event.currentTarget);
    },
    [setUserPopoverAnchorEl],
  );
  const onCloseUserPopover = useCallback(() => {
    setUserPopoverAnchorEl(null);
  }, [setUserPopoverAnchorEl]);

  const avatarRender = useMemo(() => {
    return (
      <div className={classes.avatar} onClick={onOpenUserPopover}>
        <img
          className={classes.avatarImg}
          src={user?.photoURL || '/images/avatar-default.svg'}
          alt=""
        />
        <ArrowDropDownIcon color="action" />
      </div>
    );
  }, [user, onOpenUserPopover, classes]);

  return (
    <>
      <UserPopover
        open={Boolean(userPopoverAnchorEl)}
        onClose={onCloseUserPopover}
        anchorEl={userPopoverAnchorEl}
      />
      <AppBar position="sticky" className={classes.appBar} color="inherit">
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarContent}>
            {isTemporarySideBar && (
              <IconButton onClick={onOpenDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <img className={classes.logo} src="/images/logo.svg" alt="" />
            <div className={classes.toolbarContentRight}>
              <Typography variant="body1" className={classes.displayNameText}>
                {user?.displayName}
              </Typography>
              <Typography variant="body1" className={classes.emailText}>
                {user?.email}
              </Typography>
              <IconButton
                className={classes.buttonHelp}
                component="a"
                color="inherit"
                target="_blank"
                href="https://doc.account.gu.net/"
              >
                <HelpIcon />
              </IconButton>
              {avatarRender}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderBar;
