import React, { useCallback, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutesEnum } from '~/enums/route.enum';
import theme from '~/styles/theme';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

export type NavigatorItemConfig =
  | {
      path?: string;
      label: string;
      icon?: JSX.Element;
      children?: NavigatorItemConfig[];
    }
  | 'divider';

interface Props extends StyledComponentProps<typeof useStyles> {
  item: NavigatorItemConfig;
}

const NavigatorItem: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { item } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    if (item === 'divider') {
      return;
    }
    if (item.path) {
      navigate(item.path);
    } else if (item.children) {
      setOpen((o) => !o);
    }
  }, [item, navigate]);

  if (item === 'divider') {
    return <Divider className={classes.divider} />;
  }
  const isSelected =
    item.path?.length === 1
      ? location.pathname === AppRoutesEnum.HOME
      : new RegExp(`^${item.path}`, 'g').test(location.pathname);

  return (
    <>
      <ListItemButton
        selected={isSelected}
        className={`${classes.listItem} ${isSelected ? classes.selectedItem : ''}`}
        onClick={handleClick}
      >
        {!!item.icon && (
          <ListItemIcon className={`${classes.listItemIcon} ${isSelected}`}>
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText className={classes.listItemLabel} primary={item.label} />
        {!!item.children && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItemButton>
      {!!item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            {item.children.map((i, index) => (
              <NavigatorItem item={i} key={index} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NavigatorItem;
