import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  name: {
    marginRight: 10,
    wordBreak: 'break-all',
  },
  cell: {
    borderBottom: `1px solid ${theme.colors.gainsboro}`,
  },
}));

export { useStyles };
