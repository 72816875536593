import React from 'react';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
// Components
import { Link } from 'react-router-dom';
// Styles
import LinkButton from '~/components/link-button';
import { AppRoutesEnum } from '~/enums/route.enum';
import { Endpoint } from '~/graphql/endpoint-service/types';
import { StyledComponentProps } from '~/types/material-ui';
import { getEndpointStatusI18n, getNetworkTypeI18n } from '~/utils/render.util';
import { useStyles } from './style';
// Types

interface Props extends StyledComponentProps<typeof useStyles> {
  endpoint: Endpoint;
}

const EndpointRow: React.FC<Props> = (props) => {
  const { endpoint } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className={classes.cell} sx={{ paddingLeft: 3 }}>
        <Typography variant="body1" className={classes.name}>
          {getEndpointStatusI18n(t, endpoint.status)}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body1" className={classes.name}>
          {endpoint.name}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body1">{getNetworkTypeI18n(t, endpoint.networkType)}</Typography>
      </TableCell>
      <TableCell className={classes.cell} align="right" sx={{ paddingRight: 3 }}>
        <LinkButton
          text={t('detail')}
          route={`${AppRoutesEnum.ENDPOINT_SERVICE}/${endpoint.endpointId}`}
          variant="contained"
          size="small"
        />
      </TableCell>
    </TableRow>
  );
};

export default EndpointRow;
