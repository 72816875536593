import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import firebase from 'firebase';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import DevelopmentProgramRegistration from './development-program-registration';
import EndpointServiceRegistration from './endpoint-service-registration';
import UserLayout from '~/components/app-layout';
import { withApolloClient } from '~/graphql/client';
import { StyledComponentProps } from '~/types/material-ui';

import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const HomePage = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const user = firebase.auth().currentUser;

  useEffect(() => {
    document.title = t('home');
  }, [t]);

  return (
    <UserLayout>
      <Typography variant="h5">{t('home_page.title', { name: user?.displayName })}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className={classes.lastLogin}
          component="div"
        >
          {`${t('home_page.last_login')} ${moment(user?.metadata?.lastSignInTime).format(t('date_format')) || ''} `}
        </Typography>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6}>
          <DevelopmentProgramRegistration />
        </Grid>
        <Grid item xs={12} md={6}>
          <EndpointServiceRegistration />
        </Grid>
      </Grid>
    </UserLayout>
  );
};

export default compose(withApolloClient)(HomePage);
