import 'firebase/auth';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';

import firebase from 'firebase/app';
import { Navigate, useLocation } from 'react-router';

import ScreenLoading from '~/components/screen-loading';

let needEmailVerifiedValue: boolean;
let whenAuthedValue: string;
let whenUnauthedValue: string;
let LoadingComponentValue: boolean;

const FirebaseUserContext = createContext<firebase.User | null>(null);
export const useFirebaseUser = () => useContext(FirebaseUserContext);

export type InitFirebaseAuthOptions = {
  firebaseConfig: Record<string, any>;
  needEmailVerified?: boolean;
  whenAuthed?: string;
  whenUnauthed?: string;
  LoadingComponent?: any;
};

export const initFirebaseAuth = ({
  firebaseConfig,
  needEmailVerified = false,
  whenAuthed = '/auth',
  whenUnauthed = '/',
  LoadingComponent = ScreenLoading,
}: InitFirebaseAuthOptions) => {
  needEmailVerifiedValue = needEmailVerified;
  whenAuthedValue = whenAuthed;
  whenUnauthedValue = whenUnauthed;
  LoadingComponentValue = LoadingComponent;
  if (firebase.apps.length < 1) {
    firebase.initializeApp(firebaseConfig);
  }
};

export type WithFirebaseAuthOptions = {
  whenAuthed?: string;
  whenUnauthed?: string;
  LoadingComponent?: any;
  needEmailVerified?: boolean;
};

export const WithFirebaseUser: React.FC<React.PropsWithChildren<WithFirebaseAuthOptions>> = (
  props,
) => {
  const {
    whenAuthed = whenAuthedValue,
    whenUnauthed = whenUnauthedValue,
    LoadingComponent = LoadingComponentValue,
    needEmailVerified = needEmailVerifiedValue,
  } = props;
  const location = useLocation();
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(
    firebase.auth().currentUser,
  );
  const [loading, setLoading] = useState(!firebaseUser);
  const [, forceUpdate] = useReducer((x) => !x, false); // Ref: https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      setFirebaseUser((oldData) => {
        // In case user re-login different account, we don't update data to avoid unnecessary re-render
        // Related component: src/components/re-login-dialog/index.tsx
        if (oldData && user && oldData.uid !== user.uid) {
          return oldData;
        }
        return user;
      });
      // WORKAROUND: Not update mfa phone
      setTimeout(() => {
        forceUpdate();
      }, 500);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  const isAuthed = !!firebaseUser && (needEmailVerified ? firebaseUser.emailVerified : true);

  if (!isAuthed && location.pathname !== whenUnauthed) {
    return <Navigate to={whenUnauthed} replace={true} />;
  }

  if (isAuthed && location.pathname === whenUnauthed) {
    return <Navigate to={whenAuthed} replace />;
  }

  return (
    <FirebaseUserContext.Provider value={firebaseUser}>
      {props.children}
    </FirebaseUserContext.Provider>
  );
};
