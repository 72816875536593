import React, { useCallback, useState } from 'react';
// Components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// Styles
import { useTranslation } from 'react-i18next';
import LinkButton from '~/components/link-button';
import { termConfig } from '~/configs/term.config';
import { AppRoutesEnum } from '~/enums/route.enum';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {
  handleAccept: () => void;
  loading?: boolean;
}

export default function TermDevelopmentSection(props: Props) {
  const { handleAccept, loading } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();

  const [agree, setAgree] = useState(false);
  const [loadingTerms, setLoadingTerms] = useState(true);

  const onCheck = useCallback(() => {
    setAgree(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    handleAccept();
  }, [handleAccept]);

  const onTermsLoaded = useCallback(() => {
    setLoadingTerms(false);
  }, []);

  return (
    <div>
      <Typography variant="h5">{t('development_program_page.term_title')}</Typography>
      <Typography variant="body1" color="textSecondary" className={classes.description}>
        {t('development_program_page.description')}
      </Typography>

      <div className={classes.content}>
        <div className={classes.term}>
          <iframe
            src={
              i18n.language === 'ja'
                ? termConfig.termOfUseDevelopmentJa
                : termConfig.termOfUseDevelopmentEn
            }
            width="100%"
            height={400}
            frameBorder="0"
            hidden={loadingTerms}
            onLoad={onTermsLoaded}
          />
          {loadingTerms && <Skeleton variant="rectangular" width="100%" height={400} />}
        </div>
      </div>
      <div className={classes.wrapCheckBox}>
        <div className={classes.checkbox}>
          <FormControlLabel
            control={<Checkbox />}
            checked={agree}
            label={t('terms_of_use.agree_the_terms_of_use')}
            onChange={onCheck}
          />
        </div>
        <div className={classes.wrapButtons}>
          <LinkButton
            text={t('back_home')}
            color="primary"
            route={AppRoutesEnum.HOME}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            onClick={handleSubmit}
            disabled={loading || !agree}
            endIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {t('register')}
          </Button>
        </div>
      </div>
    </div>
  );
}
