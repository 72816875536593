import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import DevelopmentResourceSection from './development-resource';
import TermDevelopmentSection from './term-condition';
import UserLayout from '~/components/app-layout';
import Loading from '~/components/loading';
import { withApolloClient } from '~/graphql/client';
import {
  useAcceptDevProgramTermOfServiceMutation,
  useGetDevProgramMemberStatusQuery,
} from '~/graphql/development-program/types';
import { StyledComponentProps } from '~/types/material-ui';
import { useStyles } from './style';

interface Props extends StyledComponentProps<typeof useStyles> {}

const DevelopmentProgram = (props: Props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: devProgramMemberStatusData,
    loading: getMemberStatusLoading,
    refetch: refetchMemberStatus,
  } = useGetDevProgramMemberStatusQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [acceptDevProgramTermOfService, { loading: loadingAcceptTerm }] =
    useAcceptDevProgramTermOfServiceMutation();

  const isTermVerified =
    devProgramMemberStatusData?.getDevProgramMemberStatus?.isAcceptTermOfService;

  const handleAccept = React.useCallback(async () => {
    await acceptDevProgramTermOfService();
    await refetchMemberStatus();
  }, [acceptDevProgramTermOfService, refetchMemberStatus]);

  return (
    <UserLayout>
      <div className={classes.root} data-testid="development-program-section">
        {getMemberStatusLoading ? (
          <div className={classes.loading}>
            <Loading />
          </div>
        ) : (
          <>
            {!isTermVerified ? (
              <TermDevelopmentSection loading={loadingAcceptTerm} handleAccept={handleAccept} />
            ) : (
              <DevelopmentResourceSection />
            )}
          </>
        )}
      </div>
    </UserLayout>
  );
};
export default compose(withApolloClient)(DevelopmentProgram);
